<template>
  <div class="home">
    <div class="banner">
      <h2>Thank You！</h2>
      <h2>We have received your request.</h2>
    </div>
    <el-main>
      <div class="content">
        <p class="txt">
          Based on our data analysis, we have identified the top Medicare plans
          for you.
        </p>
        <p class="txt fnotColor">
          Click on the offers below to see your quotes.
        </p>
        <div class="cart_list">
          <div class="cart">
            <div class="title">Select My Policy</div>
            <div class="row">
              <div class="lf">
                <img src="../assets/imgs/selectmypolicy.png" />
                <el-button
                  style="
                    background: rgba(255, 130, 130, 1);
                    color: rgba(255, 255, 255, 1);
                  "
                >
                  <a
                    style="text-decoration: none; color: inherit"
                    v-pre
                    href="https://viajandocontenis.com/6b42ee25-9a1d-4e55-ae71-de9952682830?ad_id={{ad.id}}&adset_id={{adset.id}}&campaign_id={{campaign.id}}&ad_name={{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&source={{site_source_name}}&placement={{placement}}&fbclid=fbclid"
                  >
                    View My Quote
                  </a>
                </el-button>
              </div>
              <div class="rg">
                <div class="p_txt">
                  <i
                    class="el-icon-check tips-icon"
                    style="color: rgba(255, 130, 130, 1)"
                  ></i>
                  <p>Wide range of policy options.</p>
                </div>
                <div class="p_txt">
                  <i
                    class="el-icon-check tips-icon"
                    style="color: rgba(255, 130, 130, 1)"
                  ></i>
                  <p>Easy and streamlined policy selection process.</p>
                </div>
                <div class="p_txt">
                  <i
                    class="el-icon-check tips-icon"
                    style="color: rgba(255, 130, 130, 1)"
                  ></i>
                  <p>
                    Expert guidance for personalized policy recommendations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="cart">
            <div class="title">Medicare Searcher</div>
            <div class="row">
              <div class="lf">
                <img src="../assets/imgs/McAfee.png" />
                <el-button
                  style="
                    background: rgba(255, 191, 0, 1);
                    color: rgba(255, 255, 255, 1);
                  "
                >
                  <a
                    style="text-decoration: none; color: inherit"
                    v-pre
                    href="https://viajandocontenis.com/f2ef3302-f245-4907-9373-6a4bd8f20b79?a
                                        d_id={{ad.id}}&adset_id={{adset.id}}&campaign_id={{campaign.id}}&ad_name=
                                        {{ad.name}}&adset_name={{adset.name}}&campaign_name={{campaign.name}}&sour
                                        ce={{site_source_name}}&placement={{placement}}&fbclid=fbclid"
                    >View My Quote
                  </a>
                </el-button>
              </div>
              <div class="rg">
                <div class="p_txt">
                  <i class="el-icon-check tips-icon"></i>
                  <p>Extensive Medicare coverage and research.</p>
                </div>
                <div class="p_txt">
                  <i class="el-icon-check tips-icon"></i>
                  <p>Intuitive and user-friendly interface.</p>
                </div>
                <div class="p_txt">
                  <i class="el-icon-check tips-icon"></i>
                  <p>Reliable and regularly updated content.</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="cart">
                        <div class="title">RenovateMyShelter</div>
                        <div class="row">
                            <div class="lf">
                                <img
                                    src="https://img.js.design/assets/img/64fb64061163b7e9497bd3b1.png#134b68ce62c8485ee4ebbfdba2b789f6" />
                                <el-button style="background: rgba(255, 130, 130, 1);color: rgba(255, 255, 255, 1);"> View
                                    My Quote </el-button>
                            </div>
                            <div class="rg">
                                <div class="p_txt"><i class="el-icon-check tips-icon"
                                        style="color:rgba(255, 130, 130, 1);"></i>
                                    <p> Extensive renovation experience and expertise.</p>
                                </div>
                                <div class="p_txt"><i class="el-icon-check tips-icon"
                                        style="color:rgba(255, 130, 130, 1);"></i>
                                    <p> Provide customized design solutions based on your needs and budget.</p>
                                </div>
                                <div class="p_txt"><i class="el-icon-check tips-icon"
                                        style="color:rgba(255, 130, 130, 1);"></i>
                                    <p> Fair pricing and transparent cost estimates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart">
                        <div class="title">InsuranceSpecialists</div>
                        <div class="row">
                            <div class="lf">
                                <img
                                    src="https://img.js.design/assets/img/64fb6741ecf49e896ba11f72.png#adeb718ffd8657e8c6215de236e56e12" />
                                <el-button style="background: rgba(255, 191, 0, 1);color: rgba(255, 255, 255, 1);"> View My
                                    Quote </el-button>
                            </div>
                            <div class="rg">
                                <div class="p_txt"><i class="el-icon-check tips-icon"></i>
                                    <p> tailor insurance solutions to your specific requirements.</p>
                                </div>
                                <div class="p_txt"><i class="el-icon-check tips-icon"></i>
                                    <p> Deep understanding of the insurance industry. </p>
                                </div>
                                <div class="p_txt"><i class="el-icon-check tips-icon"></i>
                                    <p> save you time and effort by doing the research and shopping.</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
          <!-- <div class="cart">
                    <div class="title">RenovateMyShelter</div>
                    <div class="row">
                        <div class="lf">
                            <img src="https://img.js.design/assets/img/64fb64061163b7e9497bd3b1.png#134b68ce62c8485ee4ebbfdba2b789f6"/>
                            <el-button style="background: rgba(255, 130, 130, 1);color: rgba(255, 255, 255, 1);"> View My Quote </el-button>
                        </div>
                        <div class="rg">
                            <p><i class="el-icon-check tips-icon"></i> Extensive renovation experience and expertise.</p>
                            <p><i class="el-icon-check tips-icon"></i> Provide customized design solutions based on your needs and budget.</p>
                            <p><i class="el-icon-check tips-icon"></i> Fair pricing and transparent cost estimates.</p>
                        </div>
                    </div>
                </div>
                <div class="cart">
                    <div class="title">InsuranceSpecialists</div>
                    <div class="row">
                        <div class="lf">
                            <img src="https://img.js.design/assets/img/64fb6741ecf49e896ba11f72.png#adeb718ffd8657e8c6215de236e56e12"/>
                            <el-button style="background: rgba(255, 191, 0, 1);color: rgba(255, 255, 255, 1);"> View My Quote </el-button>
                        </div>
                        <div class="rg">
                            <p><i class="el-icon-check tips-icon"></i> insurance solutions to your specific requirements.</p>
                            <p><i class="el-icon-check tips-icon"></i> Deep understanding of the insurance industry.</p>
                            <p><i class="el-icon-check tips-icon"></i> save you time and effort by doing the research and shopping.</p>
                        </div>
                    </div>
                </div> -->
        </div>
      </div>
    </el-main>
    <slideBar></slideBar>
  </div>
</template>

<!--Offer Conversion: findMedicareChoice -->
<script language="javascript" type="text/javascript">
var afoffer_id = 1;
</script>
<script
  language="javascript"
  type="text/javascript"
  src="https://samsonads.g2afse.com/track.js"
></script>
<!-- End Offer Conversion -->

<script>
import { GetIP, GetIPadd } from "@/api/index";
export default {
  name: "Result",
  components: {},
  data() {
    return {
      MyAdd: "",
    };
  },
  mounted() {},
  methods: {
    async getIP() {
      if (!localStorage.getItem("userip")) {
        const res = await GetIP();
        this.getipadd(res.ip);
      } else {
        this.MyAdd = JSON.parse(localStorage.getItem("userip")).city;
      }
    },
    async getipadd(ip) {
      const res = await GetIPadd(ip);
      this.MyAdd = res.city;
      localStorage.setItem("userip", JSON.stringify(res));
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/layout.scss";

.home {
  margin-top: 70px;

  .banner {
    background-image: url("../assets/imgs/banner3.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100px;
    padding: 100px 0px;
    text-align: center;

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      line-height: 50px;
      font-size: 25px;
      color: rgba(255, 255, 255, 1);
    }
  }

  .el-main {
    padding-bottom: 200px;
    overflow: hidden;

    .content {
      width: 80%;
      margin: 20px auto;

      .txt {
        font-size: 24px;
        font-weight: 400;
        line-height: 2;
      }

      .txt.fnotColor {
        color: rgba(0, 189, 189, 1);
      }

      .cart_list {
        width: 100%;
        clear: both;
        // min-height: 550px;
        display: flex;
        flex-wrap: wrap;
      }

      .cart {
        width: calc(50% - 5%);
        min-height: 200px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 19px rgba(199, 199, 199, 0.4);
        margin: 20px 5% 20px 0px;

        .title {
          font-size: 20px;
          font-weight: 550;
          text-align: center;
          line-height: 2;
          margin-top: 20px;
        }

        .row {
          display: flex;
          padding: 20px;
          padding-top: 10px;

          .lf {
            width: 150px;
            text-align: center;
            position: relative;

            img {
              width: 120px;
              margin-top: 12px;
              margin-bottom: 22px;
            }

            padding: 10px;
          }

          .rg {
            flex: 1;

            .p_txt {
              margin: 20px 0px;
              display: flex;

              .tips-icon {
                color: rgba(255, 191, 0, 1);
                display: block;
                line-height: 1.5;
                margin-right: 5px;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .home {
    .banner {
      h2 {
        font-size: 20px;
      }
    }

    .el-main {
      .content {
        width: 89%;

        .txt {
          font-size: 20px;
        }

        .cart {
          width: calc(99% - 20px);
          float: none;
          margin: 10px;

          .row {
            flex-wrap: wrap;

            .lf,
            .rg {
              flex: 100%;
            }

            .lf {
              .el-button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    .banner {
      height: 100px;
      padding: 40px 0px;

      h2 {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .el-main {
      .content {
        width: 94%;

        .txt {
          font-size: 16px;
        }

        .cart {
          width: calc(99% - 20px);
          float: none;
          margin: 10px;

          .row {
            flex-wrap: wrap;

            .lf,
            .rg {
              flex: 100%;
            }

            .lf {
              .el-button {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
