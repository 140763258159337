// 管理整个项目的接口
import axios from "axios";
import { apiUrl, leadUrl } from "../config/env";
var web_id = 43;

// axios.defaults.headers.common['token'] = 'e62337a1a49baaf03e1a05b42f5b27d426d7875e02157d294cdaaa82'

// 表单提交
export const ContactUsFrom = (data) => {
  return axios
    .post(`${apiUrl}WEBManagement/product/v1/`, data, {
      headers: {
        token: "e62337a1a49baaf03e1a05b42f5b27d426d7875e02157d294cdaaa82",
      },
    })
    .then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          Promise.reject();
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
};

// 更新表单
export const UpUsFrom = (data) => {
  return axios
    .patch(`${apiUrl}WEBManagement/product/v1/`, data, {
      headers: {
        token: "e62337a1a49baaf03e1a05b42f5b27d426d7875e02157d294cdaaa82",
      },
    })
    .then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          Promise.reject();
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
};

// 获取IP地址
export const GetIP = (data) => {
  return axios.get("https://api.ipify.org?format=json").then(
    (res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        Promise.reject();
      }
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    }
  );
};
// 获取ip地地理位置
export const GetIPadd = (data) => {
  return axios.get(`https://ipinfo.io/${data}?token=cf2580b6a6f57a`).then(
    (res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        Promise.reject();
      }
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    }
  );
};

// 获取IP地址
export const getIpAddress = () => {
  return axios.get(`https://admax.vqmjc.cc/api/AssistManagement/v/v1vip`).then(
    (res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        Promise.reject();
      }
    },
    (error) => {
      console.log(error);
      return Promise.reject();
    }
  );
};
// 加州隐私权表单提交
export const CaPrivacyFrom = (data) => {
  return axios
    .post(`${apiUrl}WEBManagement/page/v1/contactus`, data, {
      headers: {
        token: "e62337a1a49baaf03e1a05b42f5b27d426d7875e02157d294cdaaa82",
      },
    })
    .then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          Promise.reject();
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
};

// 验证邮箱手机号是否有效
export const VFun = (data) => {
  return axios
    .post(`${apiUrl}WEBManagement/product/v1/vfun`, data, {
      headers: {
        token: "e62337a1a49baaf03e1a05b42f5b27d426d7875e02157d294cdaaa82",
      },
    })
    .then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          Promise.reject();
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
};

// time2lead POST模式
export const PostFrom = (data) => {
  return axios
    .post(
      `${apiUrl}WEBManagement/product/v1/vping`,
      {
        data,
        responseType: "json",
        method: "POST",
        url: "https://time2lead.leadportal.com/apiJSON.php",
      },
      {}
    )
    .then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          Promise.reject();
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
};
// time2lead PING模式
export const PingFrom = (data) => {
  return axios
    .post(
      `${apiUrl}WEBManagement/product/v1/vping`,
      {
        data,
        responseType: "json",
        method: "POST",
        url: "https://time2lead.leadportal.com/apiJSON.php",
      },
      {}
    )
    .then(
      (res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          Promise.reject();
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
};

// 验证邮箱是否有效
// export const VerifyEmailApi = data => {
//     return axios.get(`${apiUrl}WEBManagement/product/v1/vemail?d=${data}`).then(res => {
//         if (res.status === 200) {
//             return res.data;
//         } else {
//             Promise.reject();
//         }
//     }, error => {
//         console.log(error);
//         return Promise.reject();
//     })
// }

// 验证手机号是否有效
// export const VerifyPhoneApi = (data) => {
//     return axios.get(`${apiUrl}WEBManagement/product/v1/vphone?d=${data}`).then(res => {
//         if (res.status === 200) {
//             return res.data;
//         } else {
//             Promise.reject();
//         }
//     }, error => {
//         console.log(error);
//         return Promise.reject();
//     })
// };
//获取transaction_id
export function getTransactionId() {
  const fetchName = window.performance
    .getEntries()
    .filter((el) => el.name.includes("https://www.s4hotrk.com/sdk"))[0].name;
  return axios
    .get(`${fetchName}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Content-Length": 121,
      },
    })
    .then(
      (res) => {
        if (res.status === 200) {
          return res;
        } else {
          Promise.reject();
        }
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
}
